
<template>
<v-container>

    <transition >
                 <p v-if      = "this.get_Random_Nbr() == 1" class="animate__animated animate__fadeInLeftBig">  🌟</p>
                 <p v-else-if = "this.get_Random_Nbr() == 2" class="animate__animated animate__fadeInRightBig"> 🌟🌟 </p>
                 <p v-else-if = "this.get_Random_Nbr() == 3" class="animate__animated animate__fadeInTopLeft">  🌟🌟🌟 </p>
                 <p v-else-if = "this.get_Random_Nbr() == 4" class="animate__animated animate__fadeInTopRight"> 🌟🌟🌟🌟 </p>
                 <p v-else    = "this.get_Random_Nbr() == 5" class="animate__animated animate__fadeInDown">     🌟🌟🌟🌟🌟 </p>
    </transition>

    <transition >
                 <p v-if      = "this.get_Random_Nbr() == 1" class="animate__animated animate__backInLeft">   ❤️💙 </p>
                 <p v-else-if = "this.get_Random_Nbr() == 2" class="animate__animated animate__backInRight">  ❤️💙 </p>
                 <p v-else-if = "this.get_Random_Nbr() == 3" class="animate__animated animate__backInUp">     ❤️💙 </p>
                 <p v-else-if = "this.get_Random_Nbr() == 4" class="animate__animated animate__backInUp">     ❤️💙 </p>
                 <p v-else    = "this.get_Random_Nbr() == 5" class="animate__animated animate__bounceInUp">   ❤️💙 </p>
    </transition>

                                        <v-card max-width="180" elevation="2" outlined shaped >
                                                <v-card-text transition="fade-transition">
                                                                <p> when running on </p>
                                                                <p> tablet or desktop...  </p>
                                                                <p>  </p>
                                                                <p> SORT by clicking on </p>
                                                                <p> column HEADer </p>
                                                </v-card-text>
                                        </v-card><br>

                                        <v-card width = "200" class="mx-2" elevation="2" outlined shaped>

                                                <v-card-text> version _ 2021.05.06  </v-card-text> 

                                        </v-card><br>


                                        <v-card width = "220" class="mx-2" elevation="2" outlined shaped>

                                                <a href="mailto:support@WillWayNow.com?Subject=astroSympatico%20help" target="_top"  ><span class = 'help'> support@WillWayNow.com </span> </a>

                                        </v-card>
</v-container>                                 
</template>

<!--------------------------------------------------------------------------->
<script>

  import { get_Random } from '../mixins/get_Random' ;

  export default {
                    mixins: [get_Random]
  }

</script>

<!-- ---------------------------------------------------------------------- -->
<style>
            span.help {
                        display: block;

                        width:  210px;
                        height: 40px;
                        padding: 5px;

                        color: white;
                        background-color:green;
                        border: 1px solid black;  

            }
</style>
